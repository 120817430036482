import BlasterLight from './sprites/Blaster_Light_00.png';
import BlasterLightLong from './sprites/Blaster_LightLong_00.png';
import BlasterLightShort from './sprites/Blaster_LightShort_00.png';
import BlasterLong from './sprites/Blaster_Long_00.png';
import BlasterMiddle from './sprites/Blaster_Middle_00.png';
import BlasterShort from './sprites/Blaster_Short_00.png';
import BrushMini from './sprites/Brush_Mini_00.png';
import BrushNormal from './sprites/Brush_Normal_00.png';
import ChargerKeeper from './sprites/Charger_Keeper_00.png';
import ChargerLight from './sprites/Charger_Light_00.png';
import ChargerLong from './sprites/Charger_Long_00.png';
import ChargerNormal from './sprites/Charger_Normal_00.png';
import ChargerPencil from './sprites/Charger_Pencil_00.png';
import ChargerQuick from './sprites/Charger_Quick_00.png';
import ManeuverDual from './sprites/Maneuver_Dual_00.png';
import ManeuverGallon from './sprites/Maneuver_Gallon_00.png';
import ManeuverNormal from './sprites/Maneuver_Normal_00.png';
import ManeuverShort from './sprites/Maneuver_Short_00.png';
import ManeuverStepper from './sprites/Maneuver_Stepper_00.png';
import RollerCompact from './sprites/Roller_Compact_00.png';
import RollerHeavy from './sprites/Roller_Heavy_00.png';
import RollerHunter from './sprites/Roller_Hunter_00.png';
import RollerNormal from './sprites/Roller_Normal_00.png';
import RollerWide from './sprites/Roller_Wide_00.png';
import SaberLite from './sprites/Saber_Lite_00.png';
import SaberNormal from './sprites/Saber_Normal_00.png';
import ShelterCompact from './sprites/Shelter_Compact_00.png';
import ShelterNormal from './sprites/Shelter_Normal_00.png';
import ShelterWide from './sprites/Shelter_Wide_00.png';
import ShooterBlaze from './sprites/Shooter_Blaze_00.png';
import ShooterExpert from './sprites/Shooter_Expert_00.png';
import ShooterFirst from './sprites/Shooter_First_00.png';
import ShooterFlash from './sprites/Shooter_Flash_00.png';
import ShooterGravity from './sprites/Shooter_Gravity_00.png';
import ShooterHeavy from './sprites/Shooter_Heavy_00.png';
import ShooterLong from './sprites/Shooter_Long_00.png';
import ShooterNormal from './sprites/Shooter_Normal_00.png';
import ShooterPrecision from './sprites/Shooter_Precision_00.png';
import ShooterQuickLong from './sprites/Shooter_QuickLong_00.png';
import ShooterQuickMiddle from './sprites/Shooter_QuickMiddle_00.png';
import ShooterShort from './sprites/Shooter_Short_00.png';
import ShooterTripleMiddle from './sprites/Shooter_TripleMiddle_00.png';
import ShooterTripleQuick from './sprites/Shooter_TripleQuick_00.png';
import SlosherBathtub from './sprites/Slosher_Bathtub_00.png';
import SlosherDiffusion from './sprites/Slosher_Diffusion_00.png';
import SlosherLauncher from './sprites/Slosher_Launcher_00.png';
import SlosherStrong from './sprites/Slosher_Strong_00.png';
import SlosherWashtub from './sprites/Slosher_Washtub_00.png';
import SpinnerDownpour from './sprites/Spinner_Downpour_00.png';
import SpinnerHyper from './sprites/Spinner_Hyper_00.png';
import SpinnerQuick from './sprites/Spinner_Quick_00.png';
import SpinnerSerein from './sprites/Spinner_Serein_00.png';
import SpinnerStandard from './sprites/Spinner_Standard_00.png';
import StringerNormal from './sprites/Stringer_Normal_00.png';
import StringerShort from './sprites/Stringer_Short_00.png';

export default () => [
  BlasterLight,
  BlasterLightLong,
  BlasterLightShort,
  BlasterLong,
  BlasterMiddle,
  BlasterShort,
  BrushMini,
  BrushNormal,
  ChargerKeeper,
  ChargerLight,
  ChargerLong,
  ChargerNormal,
  ChargerPencil,
  ChargerQuick,
  ManeuverDual,
  ManeuverGallon,
  ManeuverNormal,
  ManeuverShort,
  ManeuverStepper,
  RollerCompact,
  RollerHeavy,
  RollerHunter,
  RollerNormal,
  RollerWide,
  SaberLite,
  SaberNormal,
  ShelterCompact,
  ShelterNormal,
  ShelterWide,
  ShooterBlaze,
  ShooterExpert,
  ShooterFirst,
  ShooterFlash,
  ShooterGravity,
  ShooterHeavy,
  ShooterLong,
  ShooterNormal,
  ShooterPrecision,
  ShooterQuickLong,
  ShooterQuickMiddle,
  ShooterShort,
  ShooterTripleMiddle,
  ShooterTripleQuick,
  SlosherBathtub,
  SlosherDiffusion,
  SlosherLauncher,
  SlosherStrong,
  SlosherWashtub,
  SpinnerDownpour,
  SpinnerHyper,
  SpinnerQuick,
  SpinnerSerein,
  SpinnerStandard,
  StringerNormal,
  StringerShort,
];
